// eslint-disable-next-line
import store from '../store';
// eslint-disable-next-line
import http from './HttpService';

// [--------------------------BEGIN USER API ROUTES------------------------------]

async function getUser(username) {
  return http().get(`/${username}`)
    .then((response) => {
      if (response) {
        store.state.collectedUrls = response.data.printOutput;
        return store.state.collectedUrls;
      }
      return null;
    });
}

// [--------------------------END USER API ROUTES------------------------------]

export default getUser;
