import axios from 'axios';
// eslint-disable-next-line
import store from '../store';

function http() {
  return axios.create({
    baseURL: store.state.apiUrl,
  });
}

export default http;
