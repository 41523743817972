import Vue from 'vue';
import Axios from 'axios';
import Typewriter from 'vue-typewriter';
import store from './store';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.prototype.$axios = Axios;

Vue.use(Typewriter);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
