<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center">
      <v-col class="fill-height" justify="center" align="center">
        <v-row justify="center" align="center">
          <h1>
            <typewriter
            :speed="400"
            :full-erase="true"
            :interval="600"
            :cursor="false"
            :words='[
            "sus...",
            "f*cked up...",
            "weird...",
            "shady...",
            "strange...",
            "odd...",
            "peculiar indeed...",
            "dubious...",
            "questionable...",
            "sketchy...",
            "sly...",
            "d*ckish..."
            ]'>
            That's actually kinda
          </typewriter>
          </h1>
        </v-row>
        <v-row v-if="showLoading" class="mt-12" justify="center" align="center">
          <h1>Looking for stuff... 👀 Gimme a min... </h1>
        </v-row>
        <v-row v-if="!showResponse && !showLoading" class="mt-12" justify="center" align="center">
          <v-text-field
          class="shrink"
          placeholder="Enter a social media username"
          v-model="inputText"
          clearable
          outlined
          rounded
          style="min-width:650px"
          color="white"
          >
          </v-text-field>
        </v-row>
        <v-row v-if="!showResponse && !showLoading" justify="center" align="center">
          <v-btn
            @click="runUsername"
            rounded
            outlined
            color="white"
            dark
            large
            style="min-width:400px"
          >
            Submit
          </v-btn>
        </v-row>
        <v-row v-if="showResponse && !showLoading" justify="center" align="center">
          <v-simple-table
           dark
           height="450px"
           class="mt-12"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="center">
                    URLs
                  </th>
                  <th>
                    Open
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in $store.state.collectedUrls"
                  :key="item"
                >
                  <td class="text-start">{{ item }}</td>
                  <td class="text-center">
                    <v-btn
                      icon
                      color="white"
                      @click="handleRowClicked(item)"
                    >
                      <v-icon>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-row>
        <v-row v-if="showResponse" justify="center" align="center">
          <v-btn
            @click="resetUI"
            class="mt-12"
            rounded
            outlined
            color="white"
            dark
            large
            style="min-width:400px"
          >
            Run another...
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-alert
        v-model="alert"
        dismissible
        color="gray"
        elevation="2"
        outlined
        icon="mdi-alert-circle-outline"
      >
        smth done goofed, probably your fault ¯\_(ツ)_/¯
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import GenericService from '../services/GenericService';

export default {
  name: 'Home',
  data: () => ({
    inputText: '',
    alert: false,
    showResponse: false,
    showLoading: false,
  }),
  methods: {
    handleRowClicked(item) {
      window.open(item, '_blank');
    },
    resetUI() {
      this.showResponse = false;
      this.inputText = '';
    },
    runUsername() {
      this.showLoading = true;
      if (!this.inputText) {
        this.alert = true;
        return;
      }
      if (this.inputText.includes(' ')) {
        const textToArg = this.inputText.split(' ', 1);
        console.log(`running with username: ${textToArg}`);
        GenericService(textToArg).then(console.log(this.$store.state.collectedUrls));
      } else {
        console.log(`running with username: ${this.inputText}`);
        GenericService(this.inputText)
          .then((response) => {
            if (response) {
              this.showLoading = false;
              this.showResponse = true;
              return console.log(response);
            }
            this.alert = true;
            return null;
          })
          .catch((err) => {
            console.log(err);
            this.alert = true;
            return null;
          });
      }
    },
  },
};
</script>

<style>
.typewriter-selected{
  background-color: rgb(255, 255, 255, 0);
}
.v-text-field__slot input {
text-align: center;
margin-left: 5%;
}
</style>
